import React from 'react';
import './TabInvestments.css';

import HelloWorld from './Helloworld';
import Plaid from './Plaid';

function TabInvestments() {
  return (
    <>
    <div>
        <HelloWorld />
        <Plaid />
        </div>


    </>
  )
}

export default TabInvestments

