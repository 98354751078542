import React from 'react';
import './TabHome.css';
import SingleDayCalendar from '../CalendarDay/CalendarDay';
import HouseList from '../HouseCard/HouseList';
import AboutUs from '../AboutUs/AboutUs';
import Subscribe from '../Sunscribe/Subscribe';


function TabHome() {
  return (
    <>
    <div class="container">
        <div class="title title-1">Upcoming Investments</div>
        <div class="title title-2">Investment Round Deadline</div>
        <div class="item item-3"><HouseList/></div>
        <div class="item item-4"><SingleDayCalendar /> 
        <Subscribe />
        <AboutUs />

        </div>

    </div>
    </>
  )
}

export default TabHome

