import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Login from './components/login/Login';
import Portal from './pages/Portal';
import Header from './components/Header/Header'; 
import Footer from './components/Footer/Footer'; 
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="App-main">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/portal" element={<Portal />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
