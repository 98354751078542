import React, { useState } from 'react';
import { logoBase64 } from './logo';
import './Header.css';

function Header() {
  const [showSubLinks, setShowSubLinks] = useState({
    section1: false,
    section2: false,
    section4: false, // Add section4 state for dropdown toggle
  });

  const handleToggleSubLinks = (section) => {
    setShowSubLinks((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <nav className="header">
      <div className="logo-container">
        <a href="/" className="logo-link">
          <img
            src={logoBase64} // Use the logoBase64 variable here
            alt="Propertunity Logo"
            className="logo"
          />
          <span className="company-name">Propertunity</span>
        </a>
      </div>
      
      <div className="nav-links">
        {/* Our Firm Section */}
        <div className="nav-item" onClick={() => handleToggleSubLinks('section1')}>
          <span className="nav-link">
            Our Firm
            <span
              className={`dropdown-icon ${showSubLinks.section1 ? 'rotated' : ''}`}
            ></span>
          </span>
          {showSubLinks.section1 && (
            <div className="sub-links">
              <a href="https://propertunityltd.com/about-us" target="_blank" rel="noopener noreferrer">About Propertunity</a>
              <a href="https://propertunityltd.com/our-people" target="_blank" rel="noopener noreferrer">Our People</a>
              <a href="https://propertunityltd.com/contact-us" target="_blank" rel="noopener noreferrer">Contact</a>
            </div>
          )}
        </div>

        {/* Our Business Section */}
        <div className="nav-item" onClick={() => handleToggleSubLinks('section2')}>
          <span className="nav-link">
            Our Business
            <span
              className={`dropdown-icon ${showSubLinks.section2 ? 'rotated' : ''}`}
            ></span>
          </span>
          {showSubLinks.section2 && (
            <div className="sub-links">
              <a href="https://propertunityltd.com/equity" target="_blank" rel="noopener noreferrer">Equity</a>
              <a href="https://propertunityltd.com/bonds" target="_blank" rel="noopener noreferrer">Fixed Income</a>
              <a href="https://propertunityltd.com/investments" target="_blank" rel="noopener noreferrer">Investments</a>
            </div>
          )}
        </div>

        {/* Our Impact Section */}
        <div className="nav-item" onClick={() => handleToggleSubLinks('section4')}>
          <span className="nav-link">
            Our Impact
            <span
              className={`dropdown-icon ${showSubLinks.section4 ? 'rotated' : ''}`}
            ></span>
          </span>
          {showSubLinks.section4 && (
            <div className="sub-links">
              <a href="https://propertunityltd.com/community" target="_blank" rel="noopener noreferrer">Community</a>
              <a href="https://propertunityltd.com/owners" target="_blank" rel="noopener noreferrer">Owners</a>
            </div>
          )}
        </div>

        {/* Other Links */}
        <a href="https://propertunityltd.com/investor-login" target="_blank" rel="noopener noreferrer">Investor Login</a>
        {/* <Link to="/compliance">Compliance</Link> */}
      </div>
    </nav>
  );
}

export default Header;
