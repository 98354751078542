import React, { useState, useEffect } from 'react';
import supabase from '../../config/supabaseClient';
import HouseCard from './HouseCard';
import './House.css';
import Pagination from '../Pagination/Pagination';

function HouseList() {
  const [fetchError, setFetchError] = useState(null);
  const [houses, setHouses] = useState([]);
  const [loading, setLoading] = useState(true); // Changed to true initially
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);

  useEffect(() => {
    const fetchHouses = async () => {
      try {
        const { data, error } = await supabase
          .from('houses')
          .select();

        if (error) {
          setFetchError('Could not fetch the houses');
          setHouses([]);
          console.log(error);
        }

        if (data) {
          setHouses(data);
          setFetchError(null);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchHouses();
  }, []);

  if (loading && houses.length === 0) {
    return <h2>Loading...</h2>;
  }

  // Get current houses
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentRecords = houses.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(houses.length/postsPerPage);

  return (
      <div className="gridContainer">
        <div className="housesList">
          {fetchError && (
            <p className="text-red-500">{fetchError}</p>
          )}
  
          {houses.length > 0 ? (
            <div className="house-grid">
              {currentRecords.map((house) => (
                <HouseCard key={house.id} house={house} />
              ))}
            </div>
          ) : (
            !fetchError && <p>No houses available</p>
          )}
        </div>
        <div className="pagination-container">
          <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
        </div>
      </div>
    );
  }

export default HouseList;