import React from 'react';
import './CalendarDay.css';

const SingleDayCalendar = () => {
  // Placeholder date
  const date = new Date('2025-01-12'); // January 12th, 2025

  
  return (
    <div className="single-day-calendar">
      {/* Calendar Header with Month/Year */}
      <div className="calendar-header">
        <span>{date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>
      </div>

      {/* Calendar Date Section */}
      <div className="calendar-date">
        <div className="calendar-day">{date.getDate()}</div>
        {/* <div className="calendar-month-year">{dateString}</div> */}
      </div>

      {/* Optional Footer for Weekday (e.g., Sunday) */}
      <div className="calendar-footer">
        <span>{date.toLocaleDateString('en-US', { weekday: 'long' })}</span>
      </div>
    </div>
  );
};

export default SingleDayCalendar;
