import React, { useEffect, useState } from 'react';

const HelloWorld = () => {
  const [message, setMessage] = useState(null);  

  useEffect(() => {
    const getMessage = async () => {
      const resp = await fetch('/helloworld');
      const postresp = await resp.json();
      setMessage(postresp.title);  
    };
    getMessage();
  }, []);

  return (
    <div>
      {message ? <p>{message}</p> : <p>Loading...</p>}  
    </div>
  );
};

export default HelloWorld;
