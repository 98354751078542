import './Portal.css';
import { Tabs, Tab, TabList, TabPanel } from '../components/Tabs/Tabs';
import TabHome from '../components/TabHome/TabHome';
import TabDataRoom from '../components/TabDataRoom/TabDataRoom';
import TabInvestments from '../components/TabInvestments/TabInvestments';

function Portal() {
  
  return (
    <Tabs>
      <TabList>
        <Tab index={0}>home</Tab>
        <Tab index={1}>data room</Tab>
        <Tab index={2}>investments</Tab>
        <Tab index={3}>documents</Tab>
      </TabList>

      <TabPanel index={0}><TabHome/></TabPanel>
      <TabPanel index={1}><TabDataRoom /></TabPanel>
      <TabPanel index={2}><TabInvestments /></TabPanel>
      <TabPanel index={3}>Content documents</TabPanel>
    </Tabs>
  );
}

export default Portal;
