import React, { useState } from "react";
import logoImage from '../../assets/Slide8.png';
import './Login.css';


const Login = () => {
   
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); 

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error message before attempting login
    setErrorMessage("");

    try {
      const response = await fetch('/auth.js', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }), 
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("Login Response:", data);
  
        if (data.is_authenticated) {
          alert("Login successful!");
          setErrorMessage(""); // Clear error message on successful login
          // Redirect or handle successful login
        } else {
          setErrorMessage("Invalid email or password!"); // Set error message for invalid credentials
        }
      } else {
        console.error("Error logging in:", response.status);
        setErrorMessage("Something went wrong. Please try again."); // Set error message for failed response
      }
    } catch (err) {
      console.error("Error:", err);
      setErrorMessage("Network error. Please try again later."); // Set error message for network error
    }
  };

  return (
    <div className="login-page">
      <div
        className="login-image"
        style={{ backgroundImage: `url(${logoImage})` }} // Using the imported image
      ></div>

      {/* Right Side Form */}
      <div className="login-form-container">
        <div className="login-form">
          <h2>Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {/* Display the error message if it exists */}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button type="submit">Login</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
