import React from 'react';

const HouseCard = ({ house }) => {
  // Helper function to format the opening bid
  const formatCurrency = (value) => {
    // Using the built-in Intl.NumberFormat to format to currency (USD)
    const numberFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact',  // This will automatically use K, M, etc.
      compactDisplay: 'short', // Use the shortened format (K for thousands, M for millions)
    });

    return numberFormatter.format(value);
  };

  return (
    <div className="house-card">
      <h4>{house.address}</h4>
      <p>Opening bid {formatCurrency(house.opening_bid)}</p> 
      <p>Assessed value {formatCurrency(house.assessed_value)}</p> 
    </div>
  );
};

export default HouseCard;
