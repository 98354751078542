import { TabsProvider, useTabs } from "./TabsContext.js";
import './Tabs.css';  // Import the plain CSS file

// Tabs component to wrap all Tab components
export const Tabs = ({ children, defaultIndex }) => {
  return <TabsProvider defaultIndex={defaultIndex}>{children}</TabsProvider>;
};

// TabList component to contain the list of tabs
export const TabList = ({ children }) => {
  return <div className="tabsList">{children}</div>;
};

// Tab component represents each individual tab
export const Tab = ({ index, children }) => {
  const { activeTab, setActiveTab } = useTabs();

  return (
    <div
      className={`tab ${activeTab === index ? 'activeTab' : ''}`} 
      onClick={() => setActiveTab(index)}
    >
      {children}
    </div>
  );
};

// TabPanel component displays content for each active tab
export const TabPanel = ({ index, children }) => {
  const { activeTab } = useTabs();

  return activeTab === index ? <div className="tabPanel">{children}</div> : null; 
};
