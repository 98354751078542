import React from 'react';
import './TabDataRoom.css';
import SingleDayCalendar from '../CalendarDay/CalendarDay';
import AboutUs from '../AboutUs/AboutUs';
import Subscribe from '../Sunscribe/Subscribe';
import DataRoom from '../DataRoom/DataRoom';


function TabDataRoom() {
  return (
    <>
    <div class="container">
        <div class="title title-1">Due Diligence</div>
        <div class="title title-2">Investment Round Deadline</div>
        <div class="item item-3"><DataRoom /></div>
        <div class="item item-4"><SingleDayCalendar /> 
        <Subscribe />
        <AboutUs />
        </div>

    </div>
    </>
  )
}

export default TabDataRoom

