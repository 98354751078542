import React from 'react'

function AboutUs() {
  return (
    <div>
        <h4>
            Propertunity LTD
        </h4>
        <p>
            Propertunity is a private money lending fund which helps make investments into distressed properties. We have a combined 70 years of experience in various areas of real estate, investments and wealth creation. More stuff to put in here as lorem ipsum...
        </p>

    </div>
  )
}

export default AboutUs