import React from 'react';
import './DataRoom.css';
import FilesFolders from './FilesFolders';

function DataRoom() {

  return (
    <>
      <div>
        <FilesFolders  />
      </div>
      
    </>
  );
}

export default DataRoom;
